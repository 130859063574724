.data-row {
  .nacs-btn {
    visibility: hidden;
    margin-left: 8px;
  }
  &:hover {
    .nacs-btn {
      visibility: visible;
    }
  }
}

.upc-table,
.plu-table {
  margin-bottom: 100px;
}

.sticky-footer {
  position: fixed;
  width: 100%;
  max-height: 100px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 25px;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-color: #565656;
  z-index: 99999;
}
